var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "book-menu-list" },
    [
      _c("div", [
        _c(
          "div",
          [
            _c("oss-exclecom", {
              model: {
                value: _vm.excleCon,
                callback: function($$v) {
                  _vm.excleCon = $$v
                },
                expression: "excleCon"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "commomFlexSty" }, [
          _c(
            "div",
            { staticClass: "box-card box-card-left" },
            [
              _c(
                "div",
                {
                  staticClass: "commomFlexSty commomFlexStyJA iconTop",
                  staticStyle: { "padding-bottom": "20px" }
                },
                [
                  _c("div", { staticClass: "commomFontSize" }, [
                    _vm._v("菜单")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.addClassiFicationForm.followUpTypeId,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.addClassiFicationForm,
                            "followUpTypeId",
                            $$v
                          )
                        },
                        expression: "addClassiFicationForm.followUpTypeId"
                      }
                    },
                    _vm._l(_vm.followOptions, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.followUpTypeIdTitle,
                          value: item.followUpTypeId
                        }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "menuBtnTop" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.refresh()
                            }
                          }
                        },
                        [_c("i", { staticClass: "iconfont icon-refresh" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.appendPar()
                            }
                          }
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.packUp()
                            }
                          }
                        },
                        [
                          !_vm.ispackUp
                            ? _c("span", [
                                _c("i", {
                                  staticClass: "iconfont icon-category-expand"
                                })
                              ])
                            : _c("span", [
                                _c("i", {
                                  staticClass: "iconfont icon-category-collapse"
                                })
                              ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-tree", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading.list,
                    expression: "loading.list"
                  }
                ],
                ref: "selectTree",
                staticClass: "elTreeSty",
                attrs: {
                  "expand-on-click-node": false,
                  data: _vm.data,
                  props: _vm.defaultProps,
                  "node-key": "id",
                  "default-expand-all": "",
                  draggable: "",
                  "allow-drop": _vm.allowDrop
                },
                on: {
                  "node-drag-start": _vm.handleDragStart,
                  "node-drag-enter": _vm.handleDragEnter,
                  "node-drag-leave": _vm.handleDragLeave,
                  "node-drag-over": _vm.handleDragOver,
                  "node-drag-end": _vm.handleDragEnd,
                  "node-drop": _vm.handleDrop,
                  getCheckedNodes: _vm.getNode
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var node = ref.node
                      var data = ref.data
                      return _c(
                        "span",
                        {
                          staticClass: "custom-tree-node",
                          on: {
                            click: function($event) {
                              return _vm.renderBookList(data)
                            }
                          }
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: node.label,
                                placement: "top-start"
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "custom-tree-nodeStr" },
                                [_vm._v(_vm._s(node.label))]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return (function() {
                                        return _vm.append(data, "patch")
                                      })($event)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-edit" })]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return (function() {
                                        return _vm.append(data, "post")
                                      })($event)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-plus" })]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return (function() {
                                        return _vm.remove(node, data)
                                      })($event)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return (function() {
                                        return _vm.alertQRCode(node, data)
                                      })($event)
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("二维码")])]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }
                  }
                ])
              }),
              _vm._v(" "),
              this.data.length
                ? _c(
                    "el-button",
                    { attrs: { size: "middle" }, on: { click: _vm.downAll } },
                    [_vm._v("批量下载")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "box-card box-card-right" },
            [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.addBookConDia()
                        }
                      }
                    },
                    [_vm._v("\n             点击添加图书内容\n           ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        title: _vm.dialogTitle,
                        visible: _vm.addBookConDialog,
                        "close-on-click-modal": false,
                        "close-on-press-escape": false
                      },
                      on: {
                        "update:visible": function($event) {
                          _vm.addBookConDialog = $event
                        }
                      }
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "addBookCon",
                          attrs: {
                            model: _vm.addBookConForm,
                            "label-width": "130px"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "排序" } },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "200px" },
                                attrs: { min: 0, "controls-position": "right" },
                                model: {
                                  value: _vm.addBookConForm.sequence,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addBookConForm,
                                      "sequence",
                                      $$v
                                    )
                                  },
                                  expression: "addBookConForm.sequence"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "内容" } },
                            [
                              _c("el-input", {
                                attrs: { type: "input" },
                                model: {
                                  value: _vm.addBookConForm.sentence,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addBookConForm,
                                      "sentence",
                                      $$v
                                    )
                                  },
                                  expression: "addBookConForm.sentence"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "内容翻译" } },
                            [
                              _c("el-input", {
                                attrs: { type: "input" },
                                model: {
                                  value: _vm.addBookConForm.sentenceZhCn,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addBookConForm,
                                      "sentenceZhCn",
                                      $$v
                                    )
                                  },
                                  expression: "addBookConForm.sentenceZhCn"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "音标" } },
                            [
                              _c("el-input", {
                                attrs: { type: "input" },
                                model: {
                                  value: _vm.addBookConForm.phoneticSymbol,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addBookConForm,
                                      "phoneticSymbol",
                                      $$v
                                    )
                                  },
                                  expression: "addBookConForm.phoneticSymbol"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "发言人" } },
                            [
                              _c("el-input", {
                                attrs: { type: "input" },
                                model: {
                                  value: _vm.addBookConForm.spokesman,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addBookConForm,
                                      "spokesman",
                                      $$v
                                    )
                                  },
                                  expression: "addBookConForm.spokesman"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "发言人翻译" } },
                            [
                              _c("el-input", {
                                attrs: { type: "input" },
                                model: {
                                  value: _vm.addBookConForm.spokesmanZhCn,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addBookConForm,
                                      "spokesmanZhCn",
                                      $$v
                                    )
                                  },
                                  expression: "addBookConForm.spokesmanZhCn"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "音频文件" } },
                            [
                              _c("oss-audio", {
                                on: { uploadAudio: _vm.uploadAudio },
                                model: {
                                  value: _vm.addBookConForm.pronunciation,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addBookConForm,
                                      "pronunciation",
                                      $$v
                                    )
                                  },
                                  expression: "addBookConForm.pronunciation"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "难句挑战" } },
                            [
                              _c("el-switch", {
                                model: {
                                  value: _vm.addBookConForm.difficult,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addBookConForm,
                                      "difficult",
                                      $$v
                                    )
                                  },
                                  expression: "addBookConForm.difficult"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "旁白" } },
                            [
                              _c("el-switch", {
                                model: {
                                  value: _vm.addBookConForm.scene,
                                  callback: function($$v) {
                                    _vm.$set(_vm.addBookConForm, "scene", $$v)
                                  },
                                  expression: "addBookConForm.scene"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer"
                        },
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.addBookConDialog = false
                                }
                              }
                            },
                            [_vm._v("取 消")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.IsuploadAudio,
                                plain: "",
                                loading: _vm.loadingBtn
                              },
                              on: {
                                click: function($event) {
                                  return _vm.addBookCon("addBookCon")
                                }
                              }
                            },
                            [_vm._v("确定")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading.contentList,
                      expression: "loading.contentList"
                    }
                  ],
                  staticStyle: { width: "100%", overflow: "auto" },
                  attrs: { data: _vm.tableData, height: "500px" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "sequence", label: "排序", width: "50" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "sentence", label: "内容", width: "160" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sentenceZhCn",
                      label: "内容翻译",
                      width: "100"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "phoneticSymbol",
                      label: "音标",
                      width: "90"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "spokesman", label: "发言人", width: "100" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "spokesmanZhCn",
                      label: "发言人翻译",
                      width: "100"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "pronunciation",
                      label: "音频文件",
                      width: "200"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.pronunciation))])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "难句挑战", width: "90" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.difficult
                              ? _c("span", [_vm._v("是")])
                              : _vm._e(),
                            _vm._v(" "),
                            !scope.row.difficult
                              ? _c("span", [_vm._v("否")])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "scene", label: "旁白", width: "90" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.scene
                              ? _c("span", [_vm._v("是")])
                              : _c("span", [_vm._v("否")])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createdAt",
                      width: "150",
                      label: "创建时间"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "right",
                      fixed: "right",
                      width: "150"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleUpdate(row)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                 编辑\n               "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.deletedCon(row)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                 删除\n               "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.tableData.length
                ? _c(
                    "div",
                    { staticClass: "elPagination" },
                    [
                      _c("el-pagination", {
                        staticStyle: { float: "right" },
                        attrs: {
                          background: "",
                          "current-page": _vm.query.page,
                          "page-sizes": [10, 20, 30, 50],
                          "page-size": _vm.query.size,
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.totalElements
                        },
                        on: {
                          "update:currentPage": function($event) {
                            return _vm.$set(_vm.query, "page", $event)
                          },
                          "update:current-page": function($event) {
                            return _vm.$set(_vm.query, "page", $event)
                          },
                          "update:pageSize": function($event) {
                            return _vm.$set(_vm.query, "size", $event)
                          },
                          "update:page-size": function($event) {
                            return _vm.$set(_vm.query, "size", $event)
                          },
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.classdialogTitle,
            visible: _vm.classificationDialog,
            width: "30%",
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.classificationDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "addClassiFication",
              attrs: { model: _vm.addClassiFicationForm, "label-width": "80px" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "父菜单" } },
                [
                  _c("el-input", {
                    attrs: { type: "input", disabled: true },
                    model: {
                      value:
                        _vm.addClassiFicationForm.partentClassiFicationName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.addClassiFicationForm,
                          "partentClassiFicationName",
                          $$v
                        )
                      },
                      expression:
                        "addClassiFicationForm.partentClassiFicationName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "classiFicationName",
                    label: "菜单名称",
                    rules: [{ required: true, message: "请输入菜单名称" }]
                  }
                },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.addClassiFicationForm.classiFicationName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.addClassiFicationForm,
                          "classiFicationName",
                          $$v
                        )
                      },
                      expression: "addClassiFicationForm.classiFicationName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "classiFicationName", label: "跟读类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.addClassiFicationForm.followUpTypeId,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.addClassiFicationForm,
                            "followUpTypeId",
                            $$v
                          )
                        },
                        expression: "addClassiFicationForm.followUpTypeId"
                      }
                    },
                    _vm._l(_vm.followOptions, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.followUpTypeIdTitle,
                          value: item.followUpTypeId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "sequenceNum", attrs: { label: "排序" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "200px" },
                    attrs: { min: 0, "controls-position": "right" },
                    model: {
                      value: _vm.addClassiFicationForm.sequence,
                      callback: function($$v) {
                        _vm.$set(_vm.addClassiFicationForm, "sequence", $$v)
                      },
                      expression: "addClassiFicationForm.sequence"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.cancelEditclass("addClassiFication")
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    loading: _vm.loadingBtn
                  },
                  on: {
                    click: function($event) {
                      return _vm.addClassiFication("addClassiFication")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.bookData.title,
            visible: _vm.dialogVisible,
            width: "20%",
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            opened: function($event) {
              return _vm.openDialog()
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "flex-direction": "column"
              }
            },
            [
              _c("canvas", {
                staticStyle: { width: "150px", height: "150px" },
                attrs: { id: _vm.bookData.bookMenuId }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "text", size: "mini" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return (function() {
                        return _vm.downLoadPNGClick()
                      })($event)
                    }
                  }
                },
                [_c("span", [_vm._v("下载")])]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.downAllPNG, function(item, index) {
        return _c("canvas", {
          key: index,
          staticStyle: { width: "150px", height: "150px", display: "none" },
          attrs: { id: item.bookMenuId }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }