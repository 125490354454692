"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "getSubjectList", {
  enumerable: true,
  get: function get() {
    return _dictionary.getSubjectList;
  }
});
Object.defineProperty(exports, "getSectionList", {
  enumerable: true,
  get: function get() {
    return _dictionary.getSectionList;
  }
});
Object.defineProperty(exports, "getEditionList", {
  enumerable: true,
  get: function get() {
    return _dictionary.getEditionList;
  }
});
Object.defineProperty(exports, "getGradeList", {
  enumerable: true,
  get: function get() {
    return _dictionary.getGradeList;
  }
});
Object.defineProperty(exports, "getVolumeList", {
  enumerable: true,
  get: function get() {
    return _dictionary.getVolumeList;
  }
});
Object.defineProperty(exports, "getPeriodList", {
  enumerable: true,
  get: function get() {
    return _dictionary.getPeriodList;
  }
});

var _dictionary = require("./dictionary");