"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.randomString = randomString;
exports.getFileExt = getFileExt;
exports.getDomHeight = getDomHeight;
exports.updateAvailableAreaHeight = updateAvailableAreaHeight;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.to-string");

var _cryptoJs = _interopRequireDefault(require("crypto-js"));

var _path = _interopRequireDefault(require("path"));

var _store = _interopRequireDefault(require("@/store"));

/**
 * 产生随机字符串
 *
 * @returns {string}
 */
function randomString() {
  return _cryptoJs.default.MD5("taoZhi.".concat(new Date().getTime())).toString();
}
/**
 * 获取文件扩展名
 *
 * @param {string} name
 * @returns {string}
 */


function getFileExt(_ref) {
  var name = _ref.name;
  return _path.default.extname(name);
}
/**
 * 获取 dom 高度
 *
 * @param {string} selector
 * @returns {number}
 */


function getDomHeight(selector) {
  try {
    return document.querySelector(selector).offsetHeight;
  } catch (e) {
    console.error(e);
  }

  return 0;
}
/**
 * 更新页面可显示区高度
 */


function updateAvailableAreaHeight() {
  try {
    var screen = document.body.clientHeight;
    var offset = getDomHeight('.navbar');

    _store.default.dispatch('app/updateAvailableHeight', screen - offset);
  } catch (e) {
    console.error(e);
  }
}