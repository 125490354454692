"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("../Form/SyncInfo/index.vue"));

var _index2 = _interopRequireDefault(require("../Form/OssImageInput/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'BookEdit',
  components: {
    SyncInfo: _index.default,
    OssImageInput: _index2.default
  },
  props: {
    value: {
      type: Object,
      default: function _default() {}
    } // 图书信息

  },
  data: function data() {
    return {
      rules: {
        // 表单验证规则
        title: [{
          required: true,
          message: '请填写书名',
          trigger: 'change'
        }],
        cover: [{
          required: true,
          message: '请上传封面',
          trigger: 'change'
        }],
        sectionCode: [{
          required: true,
          message: '请选择学段',
          trigger: 'change'
        }],
        gradeCode: [{
          required: true,
          message: '请选择年级',
          trigger: 'change'
        }],
        subjectCode: [{
          required: true,
          message: '请选择学科',
          trigger: 'change'
        }],
        editionCode: [{
          required: true,
          message: '请选择版本',
          trigger: 'change'
        }],
        volumeCode: [{
          required: true,
          message: '请选择阶段',
          trigger: 'change'
        }],
        periodCode: [{
          required: true,
          message: '请选择版次',
          trigger: 'change'
        }],
        followUpBookType: [{
          required: true,
          message: '请选择书籍类型',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    valueProxy: {
      // 图书信息
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    isEditMode: function isEditMode() {
      return !!this.valueProxy.resourceOpenId;
    },
    // 是否是编辑
    showCategory: function showCategory() {
      return this.valueProxy.followUpBookType === '课外';
    } // 是否显示分类

  },
  methods: {
    submit: function submit() {
      var _this = this;

      this.$refs.editBook.validate(function (valid) {
        if (valid) {
          _this.$emit('submit');
        }
      });
    }
  }
};
exports.default = _default2;