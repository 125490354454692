"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSubjectList = getSubjectList;
exports.getSectionList = getSectionList;
exports.getEditionList = getEditionList;
exports.getGradeList = getGradeList;
exports.getVolumeList = getVolumeList;
exports.getPeriodList = getPeriodList;

var _main = _interopRequireDefault(require("../../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_API_TAO_ZHI_CLOUD, "/common/dictionary/admin/"));
/**
 * 获取所有学科表信息
 *
 * @returns {Promise<*>}
 */

function getSubjectList() {
  return request.get('/syncSubject/getAll');
}
/**
 * 获取所有学段表信息
 *
 * @returns {Promise<*>}
 */


function getSectionList() {
  return request.get('/syncSection/getAll');
}
/**
 * 获取所有版本表信息
 *
 * @returns {Promise<*>}
 */


function getEditionList() {
  return request.get('/syncEdition/getAll');
}
/**
 * 获取所有年级表信息
 *
 * @returns {Promise<*>}
 */


function getGradeList() {
  return request.get('/syncGrade/getAll');
}
/**
 * 获取所有阶段表信息
 *
 * @returns {Promise<*>}
 */


function getVolumeList() {
  return request.get('/syncVolume/getAll');
}
/**
 * 获取所有版次表信息
 *
 * @returns {Promise<*>}
 */


function getPeriodList() {
  return request.get('/syncPeriod/getAll');
}