var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container", style: _vm.bookListStyle }, [
    _c(
      "div",
      [
        _c("book-edit", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          on: { submit: _vm.onSubmit, cancel: _vm.goBack },
          model: {
            value: _vm.data,
            callback: function($$v) {
              _vm.data = $$v
            },
            expression: "data"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }