"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/core-js/modules/es6.array.iterator.js");

require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/core-js/modules/es6.promise.js");

require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/core-js/modules/es6.object.assign.js");

require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("element-ui/lib/theme-chalk/index.css");

var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));

require("@/styles/index.scss");

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

require("@/icons");

var _lang = _interopRequireDefault(require("./lang"));

var _config = require("./organizationLibs/vueSsoSdk/config");

var _App = _interopRequireDefault(require("./App"));

var _mock = require("../mock");

// A modern alternative to CSS resets
// lang i18n
// global css
// icon

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
if (process.env.NODE_ENV === 'production') {
  (0, _mock.mockXHR)();
} // set ElementUI lang to EN


_vue.default.use(_elementUi.default, {
  locale: _zhCN.default,
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
}); // 如果想要中文版 element-ui，按如下方式声明
// Vue.use(ElementUI)


_vue.default.config.productionTip = false; // 设置 sso sdk

(0, _config.updateConfig)({
  accessTokenKey: process.env.VUE_APP_SSO_COOKIE_ACCESS_TOKEN_KEY,
  refreshTokenKey: process.env.VUE_APP_SSO_COOKIE_REFRESH_TOKEN_KEY,
  apiUrl: "".concat(process.env.VUE_APP_API_TAO_ZHI_CLOUD, "/security"),
  ssoUrl: process.env.VUE_APP_SSO_URL,
  registerFrom: process.env.VUE_APP_SYSTEM_OPEN_ID,
  routerRef: _router.default,
  devAccessToken: process.env.VUE_APP_SSO_AUTH_DEV_ACCESS_TOKEN,
  devRefreshToken: process.env.VUE_APP_SSO_AUTH_DEV_REFRESH_TOKEN
});
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: function render(h) {
    return h(_App.default);
  }
});