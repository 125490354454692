"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "cacheKeysDictionary", {
  enumerable: true,
  get: function get() {
    return _cacheKeys.default;
  }
});

var _cacheKeys = _interopRequireDefault(require("./cacheKeys"));