var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container", style: _vm.bookListStyle }, [
    _c(
      "div",
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading.list,
                expression: "loading.list"
              }
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.data,
              border: "",
              fit: "",
              "highlight-current-row": ""
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "id",
                label: "ID",
                width: "60",
                fixed: "",
                align: "center"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", { attrs: { prop: "title", label: "名称" } })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }