"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatList = formatList;

require("core-js/modules/web.dom.iterable");

var _main = _interopRequireDefault(require("../organizationLibs/typeIs/main"));

function arrayToTree(data) {
  if (!_main.default.isArray(data)) {
    return [];
  }

  var result = []; // 用来保存结果

  var swap = {}; // 用来临时储存列表

  data // eslint-disable-next-line no-param-reassign
  .map(function (item) {
    delete item.children;
    return item;
  }) // 删除 children 字段
  .map(function (item) {
    swap[item.id] = item;
    return item;
  }) // 将所有的内容都保存到 swap 中
  .forEach(function (item) {
    var parent = swap[item.parentId];

    if (parent) {
      (parent.children || (parent.children = [])).push(item);
    } else {
      result.push(item);
    }
  });
  return result;
}

function formatList(list) {
  return arrayToTree(list);
} // eslint-disable-line import/prefer-default-export