"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _util = require("./utils/util");

var _qrcode = require("./utils/qrcode");

//
//
//
//
//
//
var _default = {
  name: 'App',
  computed: {
    debouncedOnResize: function debouncedOnResize() {
      return _lodash.default.debounce(_util.updateAvailableAreaHeight, 350);
    }
  },
  mounted: function mounted() {
    this.setOnResize();
    this.debouncedOnResize();
    (0, _qrcode.handleData)();
  },
  methods: {
    setOnResize: function setOnResize() {
      try {
        window.addEventListener('resize', this.debouncedOnResize, false);
      } catch (e) {
        console.error(e);
      }
    }
  }
};
exports.default = _default;