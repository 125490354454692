"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseFileName = parseFileName;

var _path = _interopRequireDefault(require("path"));

/**
 * 解析一个文件名，返回该文件名和扩展名
 *
 * @param {?string} fileName
 * @returns {[string, string]}
 */
function parseFileName(fileName) {
  try {
    var ext = _path.default.extname(fileName).substr(1); // 扩展名


    var name = _path.default.basename(fileName, ".".concat(ext)); // 文件名


    return [name, ext];
  } catch (e) {
    console.warn(e && e.message);
    console.warn('获取文件名过程中发生错误，可能会获取到空的文件名');
    return [];
  }
}