"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _List = _interopRequireDefault(require("../../components/BookMenu/List.vue"));

//
//
//
//
//
//
var _default = {
  name: 'BookContent',
  components: {
    BookMenu: _List.default
  },
  computed: {
    bookContentStyle: function bookContentStyle() {
      return {
        height: "".concat(this.$store.state.app.availableHeight, "px")
      };
    } // 控制页面高度

  }
};
exports.default = _default;