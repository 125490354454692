"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _mockjs = _interopRequireDefault(require("mockjs"));

var data = _mockjs.default.mock({
  'items|30': [{
    id: '@id',
    title: '@sentence(10, 20)',
    'status|1': ['published', 'draft', 'deleted'],
    author: 'name',
    display_time: '@datetime',
    pageviews: '@integer(300, 5000)'
  }]
});

var _default = [{
  url: '/table/list',
  type: 'get',
  response: function response(config) {
    var items = data.items;
    return {
      code: 20000,
      data: {
        total: items.length,
        items: items
      }
    };
  }
}];
exports.default = _default;