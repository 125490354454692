"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _progressBar = require("./guard/progressBar");

var _updateTitle = _interopRequireDefault(require("./guard/updateTitle"));

function guards(router) {
  router.beforeEach(_progressBar.start);
  router.beforeEach(_updateTitle.default);
  router.afterEach(_progressBar.done);
}

var _default = guards;
exports.default = _default;