"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isUndefined = isUndefined;
exports.isNull = isNull;
exports.isBoolean = isBoolean;
exports.isString = isString;
exports.isNumber = isNumber;
exports.isObject = isObject;
exports.isSymbol = isSymbol;
exports.isFunction = isFunction;

var _type = _interopRequireDefault(require("./type"));

var _names = _interopRequireDefault(require("./names"));

function isUndefined(target) {
  return (0, _type.default)(target) === _names.default.undefined;
}

function isNull(target) {
  return (0, _type.default)(target) === _names.default.null;
}

function isBoolean(target) {
  return (0, _type.default)(target) === _names.default.boolean;
}

function isString(target) {
  return (0, _type.default)(target) === _names.default.string;
}

function isNumber(target) {
  return (0, _type.default)(target) === _names.default.number;
}

function isObject(target) {
  return (0, _type.default)(target) === _names.default.object;
}

function isSymbol(target) {
  return (0, _type.default)(target) === _names.default.symbol;
}

function isFunction(target) {
  return (0, _type.default)(target) === _names.default.function;
}