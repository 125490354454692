"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _getPageTitle = _interopRequireDefault(require("@/utils/get-page-title"));

var _default = function _default(to, from, next) {
  // set page title
  if (!to || !to.meta || !to.meta.title) {
    next();
    return;
  }

  document.title = (0, _getPageTitle.default)(to.meta.title);
  next();
};

exports.default = _default;