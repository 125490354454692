"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  TAO_ZHI_CACHE_SUBJECT: 'TAO_ZHI_CACHE_SUBJECT',
  TAO_ZHI_CACHE_SECTION: 'TAO_ZHI_CACHE_SECTION',
  TAO_ZHI_CACHE_EDITION: 'TAO_ZHI_CACHE_EDITION',
  TAO_ZHI_CACHE_GRADE: 'TAO_ZHI_CACHE_GRADE',
  TAO_ZHI_CACHE_VOLUME: 'TAO_ZHI_CACHE_VOLUME',
  TAO_ZHI_CACHE_PERIOD: 'TAO_ZHI_CACHE_PERIOD'
};
exports.default = _default;