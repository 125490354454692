"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createBook = createBook;
exports.updateBook = updateBook;
exports.readBook = readBook;
exports.deleteBook = deleteBook;
exports.readBookList = readBookList;
exports.createMenu = createMenu;
exports.updateMenu = updateMenu;
exports.readMenu = readMenu;
exports.deleteMenu = deleteMenu;
exports.readMenuList = readMenuList;
exports.resourceListContent = resourceListContent;
exports.addBookCon = addBookCon;
exports.editBookCon = editBookCon;
exports.deleteBookCon = deleteBookCon;
exports.createType = createType;
exports.updateType = updateType;
exports.readType = readType;
exports.deleteType = deleteType;
exports.readTypeList = readTypeList;
exports.uploadExcle = uploadExcle;
exports.uploadSequence = uploadSequence;
exports.uploadConSequence = uploadConSequence;
exports.getAllSyncSection = getAllSyncSection;
exports.getAllSyncGrade = getAllSyncGrade;
exports.getAllSyncVolume = getAllSyncVolume;
exports.getAllperiodCode = getAllperiodCode;
exports.getCategoryView = getCategoryView;
exports.getAllSyncEdition = getAllSyncEdition;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_API_TAO_ZHI_CLOUD, "/follow-book/admin/"));
var request1 = (0, _main.default)("".concat(process.env.VUE_APP_API_TAO_ZHI_CLOUD, "/common/dictionary/admin/"));
/**
 * 获取所有学段表信息
 * @returns {Promise<*>}
 */

function getAllSyncSection() {
  return request1.get('/syncSection/getAll');
}
/**
 * 获取所有年级表信息
 * @returns {Promise<*>}
 */


function getAllSyncGrade() {
  return request1.get('/syncGrade/getAll');
}
/**
 * 获取上下册
 * @returns {Promise<*>}
 */


function getAllSyncVolume() {
  return request1.get('/syncVolume/getAll');
}
/**
 * 获取版ci
 * @returns {Promise<*>}
 */


function getAllperiodCode() {
  return request1.get('/syncPeriod/getAll');
}
/**
 * 获取所有版本表信息
 * @returns {Promise<*>}
 */


function getAllSyncEdition() {
  return request1.get('/syncEdition/getAll');
}
/**
 * 获取所有类目表信息
 * @returns {Promise<*>}
 */


function getCategoryView() {
  return request1.get('/regimentation/getRegimentationTree');
}
/**
 * 增加图书
 *
 * @param {object} book
 * @returns {Promise<*>}
 */


function createBook(book) {
  return request.post('/book', book);
}
/**
 * 删除图书
 *
 * @param {string?} openId
 * @returns {Promise<*>}
 */


function deleteBook(openId) {
  return request.delete("/book/".concat(openId));
}
/**
 * 修改图书
 *
 * @param {string?} openId
 * @param {object} book
 * @returns {Promise<*>}
 */


function updateBook(openId, book) {
  return request.patch("/book/".concat(openId), book);
}
/**
 * 查看图书
 *
 * @param {string?} openId
 * @returns {Promise<*>}
 */


function readBook(openId) {
  return request.get("/book/".concat(openId));
}
/**
 * 图书列表
 *
 * @param {Number?} page
 * @param {Number?} size
 * @returns {Promise<*>}
 */


function readBookList(page, size, sectionCode, gradeCode, volumeCode, periodCode, category, editionCode) {
  return request.get('/book/list', {
    params: {
      page: page,
      size: size,
      sectionCode: sectionCode,
      gradeCode: gradeCode,
      volumeCode: volumeCode,
      periodCode: periodCode,
      category: category,
      editionCode: editionCode
    }
  });
}
/**
 * 增加菜单
 *
 * @param {object} menu
 * @returns {Promise<*>}
 */


function createMenu(menu) {
  return request.post('/menu', menu);
}
/**
 * 删除菜单
 *
 * @param {string?} openId
 * @returns {Promise<*>}
 */


function deleteMenu(openId) {
  return request.delete("/menu/".concat(openId));
}
/**
 * 修改菜单
 *
 * @param {string?} id
 * @param {object} menu
 * @returns {Promise<*>}
 */


function updateMenu(id, menu) {
  return request.patch("/menu/".concat(id), menu);
}
/**
 * 查看菜单
 *
 * @param {string?} openId
 * @returns {Promise<*>}
 */


function readMenu(openId) {
  return request.get("/menu/".concat(openId));
}
/**
 * 菜单列表
 *
 * @param {string?} openId
 * @returns {Promise<*>}
 */


function readMenuList(openId, followUpTypeId) {
  return request.get('/menu/list', {
    params: {
      openId: openId,
      followUpTypeId: followUpTypeId
    }
  });
}
/**
 * 根据openid，menuId查看图书内容列表
 *
 * @param {string?} openId
 * @param {string?} menuId
 * @returns {Promise<*>}
 */


function resourceListContent(openId, menuId, page, size) {
  return request.get("/content/".concat(openId, "/").concat(menuId, "/list"), {
    params: {
      page: page,
      size: size
    }
  });
}
/**
 * 增加图书内容
 *
 * @param {object} data
 * @returns {Promise<*>}
 */


function addBookCon(data) {
  return request.post('/content/', data);
}
/**
 * 根据openId, id更新，修改图书内容
 *
 * @param {String ?} openId
 * @param {String ?} id
 * @returns {Promise<*>}
 */


function editBookCon(openId, id, body) {
  return request.patch("/content/".concat(openId, "/").concat(id), body);
}
/**
 * 根据openId，id删除图书内容
 *
 * @param {String ?} openId
 * @param {String ?} id
 * @returns {Promise<*>}
 */


function deleteBookCon(openId, id) {
  return request.delete("/content/".concat(openId, "/").concat(id));
}
/**
 * 增加分类
 *
 * @param {object} type
 * @returns {Promise<*>}
 */


function createType(type) {
  return request.post('/type', type);
}
/**
 * 删除分类
 *
 * @param {string?} openId
 * @returns {Promise<*>}
 */


function deleteType(openId) {
  return request.delete("/type/".concat(openId));
}
/**
 * 修改分类
 *
 * @param {string?} openId
 * @param {object} type
 * @returns {Promise<*>}
 */


function updateType(openId, type) {
  return request.patch("/type/".concat(openId), type);
}
/**
 * 查看分类
 *
 * @param {string?} openId
 * @returns {Promise<*>}
 */


function readType(openId) {
  return request.get("/type/".concat(openId));
}
/**
 * 分类列表
 *
 * @param {Number?} page
 * @param {Number?} size
 * @returns {Promise<*>}
 */


function readTypeList(page, size) {
  return request.get('/type/list', {
    params: {
      page: page,
      size: size
    }
  });
}
/**
 * 上传excle
 * @param {object} data
 * @returns {Promise<*>}
 */


function uploadExcle(url) {
  return request.post('/content/upload', undefined, {
    params: {
      url: url
    }
  });
}
/**
 * 更新菜单排序
 * @param {object} data
 * @returns {Promise<*>}
 */


function uploadSequence(data) {
  return request.patch('/menu/request/sequence', data);
}
/**
 * 更新内容排序
 * @param {object} data
 * @returns {Promise<*>}
 */


function uploadConSequence(data) {
  return request.patch('/content/request/sequence', data);
}