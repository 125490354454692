"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.start = start;
exports.done = done;

var _nprogress = _interopRequireDefault(require("nprogress"));

_nprogress.default.configure({
  showSpinner: false
}); // NProgress Configuration


function start(to, from, next) {
  // start progress bar
  _nprogress.default.start();

  next();
}

function done() {
  // finish progress bar
  _nprogress.default.done();
}