"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeQRCode = makeQRCode;
exports.downLoadPNG = downLoadPNG;
exports.handleData = handleData;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _qrcode = _interopRequireDefault(require("qrcode"));

// 引入生成二维码插件
function makeQRCode(data) {
  console.log(data.length > 1);

  if (data.length > 1) {
    data.forEach(function (target) {
      return handleData(target);
    });
  } else {
    return handleData(data);
  }

  return true;
}

function handleData(target) {
  var canvas;
  var url = process.env.VUE_APP_TAOZHI_URL;
  console.log('url', url);

  if (undefined !== target.id) {
    canvas = document.getElementById(target.id);
    url += "".concat(target.id.toString().replace(/[^0-9]/ig, ''));
  }

  if (undefined !== target.scene) {
    url += "/".concat(target.scene === 2 ? 0 : 1);
  }

  if (undefined !== target.bookMenuId) {
    url += "/".concat(target.bookMenuId);
    canvas = document.getElementById(target.bookMenuId);
  }

  console.log(url);

  _qrcode.default.toCanvas(canvas, url, {
    width: 150,
    height: 150,
    margin: 0
  }, function (error) {
    if (error) console.error(error);
    console.log('QRCode success!');
  });

  return true;
}

function downLoadPNG(item, type) {
  var canvas;
  var oA = document.createElement('a');

  if (type === 0) {
    oA.download = "".concat(item.name, "\u7684").concat(item.title); // 设置下载的文件名

    canvas = document.getElementById(item.id);
  } else if (type === 1) {
    oA.download = "\u56FE\u4E66id\uFF1A".concat(item.id, "-\u76EE\u5F55\u6807\u9898\uFF1A").concat(item.title);
    canvas = document.getElementById(item.bookMenuId);
  }

  oA.href = canvas.toDataURL('image/png');
  document.body.appendChild(oA);
  oA.click();
  oA.remove(); // 下载之后把创建的元素删除

  return true;
}