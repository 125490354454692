var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      staticClass: "excleConWrap"
    },
    [
      _c(
        "el-form",
        {
          staticStyle: {
            margin: "20px",
            padding: "10px",
            "box-shadow": "0px 0px 4px 2px #EBEEF5",
            display: "flex"
          },
          attrs: { model: _vm.form }
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { display: "flex" },
              attrs: { "label-width": _vm.formLabelWidth }
            },
            [
              _c(
                "el-upload",
                {
                  ref: "uploadExcel",
                  attrs: {
                    headers: _vm.headers,
                    action:
                      "//api.cloud.dev.taozhi.online/follow-book/admin/content/upload",
                    limit: _vm.limitNum,
                    "auto-upload": false,
                    accept: ".xlsx",
                    "on-remove": _vm.handleRemove,
                    "before-upload": _vm.beforeUploadFile,
                    "on-change": _vm.fileChange,
                    "on-exceed": _vm.exceedFile,
                    "file-list": _vm.fileList
                  }
                },
                [
                  _c("el-button", { attrs: { size: "small", plain: "" } }, [
                    _vm._v("选择文件")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip"
                    },
                    [_vm._v("只能上传xlsx文件，且不超过10M")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { size: "small", type: "success" },
                  on: { click: _vm.uploadFile }
                },
                [_vm._v("上传到服务器")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }