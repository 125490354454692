"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  undefined: 'undefined',
  null: 'null',
  boolean: 'boolean',
  string: 'string',
  number: 'number',
  object: 'object',
  symbol: 'symbol',
  function: 'function'
};
exports.default = _default;