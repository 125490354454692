"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.only = only;
exports.strictTrim = strictTrim;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _slicedToArray2 = _interopRequireDefault(require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime-corejs2/helpers/slicedToArray"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.object.entries");

/**
 * @param {*} value
 * @returns {string|undefined}
 */
function comTrim(value) {
  try {
    if (!value) {
      return undefined;
    }

    return "".concat(value).trim();
  } catch (e) {
    return undefined;
  }
}
/**
 * 过滤 data，只保留 rule 中的 key
 *
 * @param {object} data
 * @param {array} rule
 * @returns {object}
 */


function only(data, rule) {
  var entry = Object.entries(data).filter(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        key = _ref2[0];

    return rule.includes(key);
  });
  return Object.fromEntries(entry);
}
/**
 * @param {string} key
 * @param {*} value
 * @param {array} rule
 * @returns {[]}
 */


function strictTrimItem(_ref3, rule) {
  var _ref4 = (0, _slicedToArray2.default)(_ref3, 2),
      key = _ref4[0],
      value = _ref4[1];

  if (rule.includes(key)) {
    var newValue = comTrim(value) ? comTrim(value) : undefined;
    return [key, newValue];
  }

  return [key, value];
}
/**
 * 对于 data 中的所有 array 进行 trim，并且将空字符串置为 undefined
 *
 * @param {object} data
 * @param {array} rule
 * @returns {object}
 */


function strictTrim(data, rule) {
  var entry = Object.entries(data).map(function (item) {
    return strictTrimItem(item, rule);
  });
  return Object.fromEntries(entry);
}