"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _qs = _interopRequireDefault(require("qs"));

/**
 * axios 配置文件
 *
 * @type {{baseURL: string, paramsSerializer: function, timeout: number}}
 */
var config = {
  baseURL: process.env.VUE_APP_API_TAO_ZHI_CLOUD,
  timeout: 20 * 1000,
  // 请求超时时间
  paramsSerializer: function paramsSerializer(params) {
    return _qs.default.stringify(params, {
      arrayFormat: 'brackets'
    });
  }
};
var _default = config;
exports.default = _default;