"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _typeof2 = _interopRequireDefault(require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime-corejs2/helpers/typeof"));

var _names = _interopRequireDefault(require("./names"));

/**
 * 判断一个对象是否是 null
 *
 * @param {Object} value 要判断的对象
 * @returns {boolean} 如果 value 是 null，则返回 true
 */
function isNull(value) {
  return value === null;
}
/**
 * 判断一个变量的类型，可以正确的区别 object 和 null
 *
 * @param {any} value 要判断的变量
 * @returns {string} 该变量的类型
 */


function type(value) {
  var typeName = (0, _typeof2.default)(value);

  if (typeName === _names.default.object) {
    // 如果 value 是 object 的话，判断一下是不是 null
    return isNull(value) ? _names.default.null : typeName;
  }

  return typeName;
}

var _default = type;
exports.default = _default;