"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _default = {
  namespaced: true,
  state: {
    sidebar: {
      opened: _jsCookie.default.get('sidebarStatus') ? !!+_jsCookie.default.get('sidebarStatus') : true,
      withoutAnimation: false
    },
    device: 'desktop',
    availableHeight: 0
  },
  mutations: {
    TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
      state.sidebar.opened = !state.sidebar.opened;
      state.sidebar.withoutAnimation = false;

      if (state.sidebar.opened) {
        _jsCookie.default.set('sidebarStatus', 1);
      } else {
        _jsCookie.default.set('sidebarStatus', 0);
      }
    },
    CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
      _jsCookie.default.set('sidebarStatus', 0);

      state.sidebar.opened = false;
      state.sidebar.withoutAnimation = withoutAnimation;
    },
    TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
      state.device = device;
    },
    UPDATE_AVAILABLE_HEIGHT: function UPDATE_AVAILABLE_HEIGHT(state, availableHeight) {
      // 格式化 availableHeight
      var height = 0;

      try {
        height = parseInt(availableHeight, 10);
      } catch (e) {
        console.error(e);
      }

      try {
        if (height < 1) {
          // 高度值无效
          if (state.availableHeight < 1) {
            state.availableHeight = document.body.clientHeight;
          } // 如果原没有高度值，则将高度初始化为页面高度

        } else {
          state.availableHeight = availableHeight; // 更新可用区域高度
        }
      } catch (e) {
        console.error(e);
      }
    }
  },
  actions: {
    toggleSideBar: function toggleSideBar(_ref) {
      var commit = _ref.commit;
      commit('TOGGLE_SIDEBAR');
    },
    closeSideBar: function closeSideBar(_ref2, _ref3) {
      var commit = _ref2.commit;
      var withoutAnimation = _ref3.withoutAnimation;
      commit('CLOSE_SIDEBAR', withoutAnimation);
    },
    toggleDevice: function toggleDevice(_ref4, device) {
      var commit = _ref4.commit;
      commit('TOGGLE_DEVICE', device);
    },
    updateAvailableHeight: function updateAvailableHeight(_ref5, availableHeight) {
      var commit = _ref5.commit;
      commit('UPDATE_AVAILABLE_HEIGHT', availableHeight);
    }
  }
};
exports.default = _default;