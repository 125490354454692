"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isAvailableToken = isAvailableToken;
exports.getAccessToken = getAccessToken;
exports.setAccessToken = setAccessToken;
exports.removeAccessToken = removeAccessToken;
exports.getRefreshToken = getRefreshToken;
exports.setRefreshToken = setRefreshToken;
exports.removeRefreshToken = removeRefreshToken;

var _main = _interopRequireDefault(require("../../typeIs/main"));

var _cookie = require("./cookie");

var _crypto = require("./crypto");

var _config = require("../config");

var _host = require("./host");

// eslint-disable-next-line import/no-cycle

/**
 * 检查 tokens 是否是正确的格式
 *
 * @param {string} token
 * @returns {boolean}
 */
function isAvailableToken(token) {
  try {
    return _main.default.isString(token) && !_main.default.isEmpty(token);
  } catch (e) {
    console.error(e);
    return false;
  }
}
/**
 * 获取 token
 *
 * @param {string} key
 * @returns {string|undefined}
 */


function getToken(key) {
  try {
    var ciphertext = (0, _cookie.getCookie)(key);
    var token = (0, _crypto.decrypt)(ciphertext);

    if (!isAvailableToken(token)) {
      return undefined;
    } // 如果 token 无效，返回 undefined


    return token;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
/**
 * 删除 token
 */


function removeToken(key) {
  try {
    (0, _cookie.removeCookie)(key);
  } catch (e) {
    console.error(e);
  }
}
/**
 * 设置 token
 *
 * @param {string} key
 * @param {string} token
 * @param {number} expires
 * @returns {string}
 */


function setToken(key, token) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref$expires = _ref.expires,
      expires = _ref$expires === void 0 ? (0, _config.getConfig)().expires : _ref$expires;

  try {
    if (!isAvailableToken(token)) {
      // 如果 token 不是有效的 token，则删除 token
      removeToken(key);
      return undefined;
    }

    var ciphertext = (0, _crypto.encrypt)(token);
    (0, _cookie.setCookie)(key, ciphertext, {
      expires: expires
    });
    return token;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
/**
 * 删除 access token
 */


function removeAccessToken() {
  if (!(0, _host.isEffectiveDomain)()) {
    (0, _config.getConfig)().devAccessToken = undefined;
    return;
  } // 开发模式下


  removeToken((0, _config.getConfig)().accessTokenKey);
}
/**
 * 获取 refresh token
 *
 * @returns {string|undefined}
 */


function getRefreshToken() {
  if (!(0, _host.isEffectiveDomain)()) {
    return (0, _config.getConfig)().devRefreshToken;
  } // 开发模式下


  return getToken((0, _config.getConfig)().refreshTokenKey);
}
/**
 * 删除 refresh token
 */


function removeRefreshToken() {
  if (!(0, _host.isEffectiveDomain)()) {
    (0, _config.getConfig)().devRefreshToken = undefined;
    return;
  } // 开发模式下


  removeAccessToken(); // 删除 refresh token 同时也删除 access token

  removeToken((0, _config.getConfig)().refreshTokenKey);
}
/**
 * 设置 refresh token
 *
 * @param {string} refreshToken
 * @returns {string}
 */


function setRefreshToken(refreshToken) {
  if (!(0, _host.isEffectiveDomain)()) {
    (0, _config.getConfig)().devRefreshToken = refreshToken;
    return (0, _config.getConfig)().devRefreshToken;
  } // 开发模式下


  if (!isAvailableToken(refreshToken)) {
    // 如果 refresh token 无效，则删除 refresh token
    removeRefreshToken();
    return undefined;
  }

  return setToken((0, _config.getConfig)().refreshTokenKey, refreshToken);
}
/**
 * 获取 access token
 *
 * @returns {string|undefined}
 */


function getAccessToken() {
  if (!(0, _host.isEffectiveDomain)()) {
    return (0, _config.getConfig)().devAccessToken;
  } // 开发模式下


  if (!getRefreshToken()) {
    return undefined;
  } // 如果 refresh token 无效，则返回 undefined


  return getToken((0, _config.getConfig)().accessTokenKey);
}
/**
 * 设置 access token
 *
 * @param {string} accessToken
 * @param {number} expires token 有效期；为了避免过期，有效时间会被缩短 40s
 * @returns {string}
 */


function setAccessToken(accessToken) {
  var expires = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var deviation = 40; // 偏差时间，该时间将使 token 先于服务器过期，这样可以避免 token 过期的场景

  if (!(0, _host.isEffectiveDomain)()) {
    (0, _config.getConfig)().devAccessToken = accessToken;
    return (0, _config.getConfig)().devAccessToken;
  } // 开发模式下


  if (!isAvailableToken(accessToken)) {
    // 如果 access token 无效，则删除 access token
    removeAccessToken();
    return undefined;
  }

  if (expires < deviation) {
    // 如果有效时间少于 20，删除 access token
    removeAccessToken();
    return undefined;
  }

  return setToken((0, _config.getConfig)().accessTokenKey, accessToken, {
    expires: expires - deviation
  });
}