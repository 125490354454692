var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "oss-image"
    },
    [
      _c("input", {
        ref: "uploadControl",
        staticClass: "oss-file",
        attrs: { type: "file" },
        on: { change: _vm.upload }
      }),
      _vm._v(" "),
      _vm.valueProxy
        ? _c("div", [_vm._v(_vm._s(_vm.valueProxy))])
        : _c("div", { staticClass: "oss-indicator" }, [
            _c("i", { staticClass: "oss-upload-icon el-icon-plus" })
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }