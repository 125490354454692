var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "editBook",
      attrs: { rules: _vm.rules, model: _vm.valueProxy, "label-width": "80px" }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "书名", prop: "title" } },
        [
          _c("el-input", {
            model: {
              value: _vm.valueProxy.title,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "title", $$v)
              },
              expression: "valueProxy.title"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "封面", prop: "cover" } },
        [
          _c("oss-image-input", {
            model: {
              value: _vm.valueProxy.cover,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "cover", $$v)
              },
              expression: "valueProxy.cover"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "学段", prop: "sectionCode" } },
        [
          _c("sync-info", {
            attrs: { type: "section" },
            model: {
              value: _vm.valueProxy.sectionCode,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "sectionCode", $$v)
              },
              expression: "valueProxy.sectionCode"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "年级", prop: "gradeCode" } },
        [
          _c("sync-info", {
            attrs: { type: "grade" },
            model: {
              value: _vm.valueProxy.gradeCode,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "gradeCode", $$v)
              },
              expression: "valueProxy.gradeCode"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "学科", prop: "subjectCode" } },
        [
          _c("sync-info", {
            attrs: { type: "subject" },
            model: {
              value: _vm.valueProxy.subjectCode,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "subjectCode", $$v)
              },
              expression: "valueProxy.subjectCode"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "版本", prop: "editionCode" } },
        [
          _c("sync-info", {
            attrs: { type: "edition" },
            model: {
              value: _vm.valueProxy.editionCode,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "editionCode", $$v)
              },
              expression: "valueProxy.editionCode"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "阶段", prop: "volumeCode" } },
        [
          _c("sync-info", {
            attrs: { type: "volume", multiple: true },
            model: {
              value: _vm.valueProxy.volumeCode,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "volumeCode", $$v)
              },
              expression: "valueProxy.volumeCode"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "版次", prop: "periodCode" } },
        [
          _c("sync-info", {
            attrs: { type: "period" },
            model: {
              value: _vm.valueProxy.periodCode,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "periodCode", $$v)
              },
              expression: "valueProxy.periodCode"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "书籍类型", prop: "followUpBookType" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.valueProxy.followUpBookType,
                callback: function($$v) {
                  _vm.$set(_vm.valueProxy, "followUpBookType", $$v)
                },
                expression: "valueProxy.followUpBookType"
              }
            },
            [
              _c("el-radio", { attrs: { value: "课内", label: "课内" } }),
              _vm._v(" "),
              _c("el-radio", { attrs: { value: "课外", label: "课外" } })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showCategory,
              expression: "showCategory"
            }
          ],
          attrs: { label: "分类" }
        },
        [
          _c("el-input", {
            model: {
              value: _vm.valueProxy.category,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "category", $$v)
              },
              expression: "valueProxy.category"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v(_vm._s(_vm.isEditMode ? "修改" : "创建"))]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("cancel")
                }
              }
            },
            [_vm._v("取消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }