var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container book-list", style: _vm.bookListStyle },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.handleCreate }
            },
            [_vm._v("\n      新建\n    ")]
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { display: "inline-flex" } }, [
            _c(
              "div",
              { staticClass: "commonFlex" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择学段" },
                    on: {
                      change: function($event) {
                        return _vm.handleSectionCode(_vm.sectionCode)
                      }
                    },
                    model: {
                      value: _vm.sectionCode,
                      callback: function($$v) {
                        _vm.sectionCode = $$v
                      },
                      expression: "sectionCode"
                    }
                  },
                  _vm._l(_vm.SyncSectionData, function(item) {
                    return _c("el-option", {
                      key: item.adcode,
                      attrs: { label: item.name, value: item.code }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "commonFlex" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择年级" },
                    on: {
                      change: function($event) {
                        return _vm.handleGradeCode(_vm.gradeCode)
                      }
                    },
                    model: {
                      value: _vm.gradeCode,
                      callback: function($$v) {
                        _vm.gradeCode = $$v
                      },
                      expression: "gradeCode"
                    }
                  },
                  _vm._l(_vm.allSyncGrade, function(item) {
                    return _c("el-option", {
                      key: item.adcode,
                      attrs: { label: item.name, value: item.code }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "commonFlex" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择上下册" },
                    on: {
                      change: function($event) {
                        return _vm.handleVolumeCode(_vm.volumeCode)
                      }
                    },
                    model: {
                      value: _vm.volumeCode,
                      callback: function($$v) {
                        _vm.volumeCode = $$v
                      },
                      expression: "volumeCode"
                    }
                  },
                  _vm._l(_vm.volumeCodetion, function(item) {
                    return _c("el-option", {
                      key: item.adcode,
                      attrs: { label: item.name, value: item.code }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "commonFlex" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择版次" },
                    on: {
                      change: function($event) {
                        return _vm.handlePeriod(_vm.periodCode)
                      }
                    },
                    model: {
                      value: _vm.periodCode,
                      callback: function($$v) {
                        _vm.periodCode = $$v
                      },
                      expression: "periodCode"
                    }
                  },
                  _vm._l(_vm.periodCodetion, function(item) {
                    return _c("el-option", {
                      key: item.adcode,
                      attrs: { label: item.name, value: item.code }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "commonFlex" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择版本" },
                    on: {
                      change: function($event) {
                        return _vm.handleEditionCode(_vm.editionCode)
                      }
                    },
                    model: {
                      value: _vm.editionCode,
                      callback: function($$v) {
                        _vm.editionCode = $$v
                      },
                      expression: "editionCode"
                    }
                  },
                  _vm._l(_vm.syncEditiontion, function(item) {
                    return _c("el-option", {
                      key: item.adcode,
                      attrs: { label: item.name, value: item.code }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "tableContainer", staticClass: "table-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading.list,
                  expression: "loading.list"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.data,
                border: "",
                fit: "",
                "highlight-current-row": "",
                height: _vm.tableHeight
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  label: "ID",
                  width: "60",
                  fixed: "",
                  align: "center"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "resourceOpenId", width: "220", label: "OpenId" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "封面图片", width: "100", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("el-image", {
                          staticStyle: { width: "76px", height: "102px" },
                          attrs: {
                            src: _vm.ossUrl(row.cover, "normal_progressive"),
                            fit: "cover"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "title", label: "书名" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "syncInfoText", label: "同步信息" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "publishedAt", label: "发布时间" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "category", label: "分类" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("showStatus")(row.state)) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "QRCode", width: "200", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.alertQRCode(row)
                              }
                            }
                          },
                          [_c("span", [_vm._v("二维码")])]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  width: "320",
                  fixed: "right",
                  "class-name": "small-padding fixed-width"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.handleRead(row)
                              }
                            }
                          },
                          [_vm._v("\n            查看\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.handleUpdate(row)
                              }
                            }
                          },
                          [_vm._v("\n            编辑\n          ")]
                        ),
                        _vm._v(" "),
                        row.state !== 3
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  loading:
                                    _vm.loading.status[row.resourceOpenId],
                                  size: "mini",
                                  type: "success"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleModifyStatus(row, 3)
                                  }
                                }
                              },
                              [_vm._v("\n            发布\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.state === 3
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  loading:
                                    _vm.loading.status[row.resourceOpenId],
                                  size: "mini",
                                  type: "danger"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleModifyStatus(row, 1)
                                  }
                                }
                              },
                              [_vm._v("\n            撤回\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !row.deletedAt
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  loading:
                                    _vm.loading.delete[row.resourceOpenId],
                                  size: "mini",
                                  type: "danger"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleDelete(row)
                                  }
                                }
                              },
                              [_vm._v("\n            删除\n          ")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.totalElements
            },
            on: {
              "update:currentPage": function($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function($event) {
                _vm.currentPage = $event
              },
              "update:pageSize": function($event) {
                _vm.pageSize = $event
              },
              "update:page-size": function($event) {
                _vm.pageSize = $event
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "二维码",
            visible: _vm.dialogVisible,
            width: "60%",
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            opened: function($event) {
              return _vm.openDialog()
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "align-items": "center"
              }
            },
            _vm._l(_vm.bookData, function(item, i) {
              return _c(
                "div",
                {
                  key: "for_" + i,
                  staticStyle: { width: "160px", "text-align": "center" }
                },
                [
                  _c("span", [_vm._v(_vm._s(item.title))]),
                  _vm._v(" "),
                  _c("canvas", {
                    staticStyle: {
                      width: "150px",
                      height: "150px",
                      "margin-top": "10px"
                    },
                    attrs: { id: item.id }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "mini" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return (function() {
                            return _vm.downLoadPNGClick(item)
                          })($event)
                        }
                      }
                    },
                    [_c("span", [_vm._v("下载")])]
                  )
                ],
                1
              )
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "qrCode" } }, [
      _c("div", { attrs: { id: "code" } }),
      _vm._v(" "),
      _c("canvas", { attrs: { id: "canvas" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }