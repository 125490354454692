"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.routerIsProtect = routerIsProtect;

/**
 * 判断一个路由是否是受保护的路由
 * @param {object} router
 * @returns {boolean}
 */
function routerIsProtect() {
  var router = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  try {
    var _router$meta = router.meta;
    _router$meta = _router$meta === void 0 ? {} : _router$meta;
    var _router$meta$protect = _router$meta.protect,
        protect = _router$meta$protect === void 0 ? true : _router$meta$protect;
    return !!protect;
  } catch (e) {
    console.error(e);
    return true;
  }
} // eslint-disable-line import/prefer-default-export