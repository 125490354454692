"use strict";

var _interopRequireDefault = require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateConfig = updateConfig;
exports.getConfig = getConfig;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/follow-book_follow-book-admin_fMUT/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var config = {
  accessTokenKey: 'TAOZHI_DEVELOP_ACCESS_TOKEN',
  refreshTokenKey: 'TAOZHI_DEVELOP_REFRESH_TOKEN',
  domain: '.taozhi.cn',
  path: '/',
  expires: 315360000,
  cipher: 'rdT3Wd4KM18ws0jFJ2WyTQUxH7GrUmyCkBBC6ra1JzXg1bY02OX36240wKAJ6L2vQCWuxcJ5uBWzS35tezKbnGYzPKbmLS2Ky3nQ2j1ipzRmodLs3gGfjkqQxJFjDRjYA3xMCqdIu0HaqqweoArnOxt1lLedX988PG52me2NAXBP7eqnZ6dmZtVv5IUEQoFQLMo8vytP93LlcoGI6fdnqomkXylGZTIgcd2Snlo7Nr244s27VaidgGYMr8yDe6ZT',
  apiUrl: 'https://api.cloud.taozhi.online/security/',
  ssoUrl: 'https://sso.dev.taozhi.cn/',
  registerFrom: 'sysdef201908231148nvsafwsg',
  routerRef: undefined,
  devAccessToken: undefined,
  devRefreshToken: undefined
};
/**
 * 更新 sso sdk 的配置
 *
 * @param newConfig
 */

function updateConfig() {
  var newConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;

  try {
    var data = _objectSpread(_objectSpread({}, config), newConfig);

    config.accessTokenKey = data.accessTokenKey;
    config.refreshTokenKey = data.refreshTokenKey;
    config.domain = data.domain;
    config.path = data.path;
    config.expires = data.expires;
    config.cipher = data.cipher;
    config.apiUrl = data.apiUrl;
    config.ssoUrl = data.ssoUrl;
    config.registerFrom = data.registerFrom;
    config.routerRef = data.routerRef;
    config.devAccessToken = data.devAccessToken;
    config.devRefreshToken = data.devRefreshToken;
  } catch (e) {
    console.error(e);
  }
}
/**
 * 获取 sso sdk 的配置
 *
 * @returns {{cipher: string, path: string, expires: number, registerFrom: string, apiUrl: string, ssoUrl: string, accessTokenKey: string, domain: string, refreshTokenKey: string, routerRef: object, devAccessToken: string, devRefreshToken: string}}
 */


function getConfig() {
  return config;
}